html, body {
    background-color: rgb(19, 21, 22) !important;
    color: rgba(255, 255, 255, .87);

    overflow-x: hidden;

    width: 100%;
    height: 100%;
    margin: 0;

    display: grid;
}

* {
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
}

#overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

#badge-beta {
    background-color: rgb(122, 14, 14);
}

#badge-analytics {
    background-color: rgb(40, 111, 17);
}

#badge-data {
    background-color: rgb(26, 142, 136);
}

.badge {
    width: fit-content;

    display: inline-block;

    margin: auto;

    padding: 10px;

    border-radius: 5px;

    background-color: #333333;
    font-size: 2vw;
}

#badge-sep {
    height: 10px;
}

#main {
    margin: auto;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
}

#main > * {
    margin: auto;
    text-align: center;
}

#title {
    font-size: 2.5vw;
}

.autocomplete {
    width: 80%;
}

input {
    display: block;

    text-align: center;

    width: 100%;
    font-size: 5vw;

    background-color: transparent;
    border: none;

    color: rgba(255, 255, 255, .9);
}

#resultContainer {
    font-size: 5vw;
}


.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
  }
  .autocomplete-items {
    position: absolute;
    border: 1px solid #666666;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;

    overflow-y: auto;
    max-height: 25vw;
  }
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #222222;
    border-bottom: 1px solid #666666;

    font-size: 3vw;
  }
  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #444444;
  }
  .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }